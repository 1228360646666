<template>
    <div class="common-column-wrap">
        <div class="list">
            <el-menu
                :default-active="active"
                :default-openeds="openeds"
                class="el-menu-vertical-demo"
                @select="(key, keyPath, $event) => handleSelect($event, key, keyPath)"
                :unique-opened="true"
                ref="menuRef"
            >
                <div v-for="(item) in list" :index="item.id">
                    <el-submenu :index="`${item.id}`" v-if="item.secondList">
                        <template slot="title">
                            <span>{{ item.classificationName }}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item v-for="(item) in item.secondList" :index="`${item.id}`" :sourceData="item">{{ item.classificationName }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item :sourceData="item" :index="`${item.id}`" v-else>
                        <span slot="title">{{ item.classificationName }}</span>
                    </el-menu-item>
                </div>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: function () {
                return []
            }
        },
        active: {
            type: String | Number,
            default: ''
        },
        openeds: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data () {
        return {
        }
    },
    methods: {
      handleSelect($event, key, keyPath) {
        this.$emit('select', $event, key, keyPath)
      },
      /**
       * 展开某一项
       */
      handleOpenMenu(index){
        this.$refs.menuRef(index)
      }
    }
}
</script>

<style lang="scss" scoped>
.common-column-wrap {
    padding: .28rem /* 28/100 */ 0;

    ::v-deep {
        .el-submenu__title:focus, .el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover {
            background-color: transparent;
            color: #AF282B;
        }
        
        .el-submenu__title, .el-menu-item {
            font-size: .2rem /* 20/100 */;
            font-weight: bold;
            color: #333333;
            padding-left: .84rem /* 84/100 */ !important;
            height: .56rem /* 56/100 */;
            line-height: .56rem /* 56/100 */;
        }
        .el-submenu__title >span {
            font-size: .2rem /* 20/100 */;
        }
        .el-menu-item.is-active {
            color: #AF282B;
        }
        
        .el-menu {
            border-right: 0;
        }
        .el-submenu .el-menu-item {
            min-width: auto;
            font-weight: normal;
            height: .45rem /* 45/100 */;
            line-height: .45rem /* 45/100 */;
            font-size: .16rem /* 16/100 */;
        }
        .el-submenu__icon-arrow {
            font-size: .15rem /* 15/100 */;
        }

        .el-menu-item {
            position: relative;

            &.is-active {
                &::before {
                    content:  '';
                    position: absolute;
                    width: .1rem /* 10/100 */;
                    height: .1rem /* 10/100 */;
                    background: #AF282B;
                    top: 50%;
                    left: 0px;
                    height: .44rem /* 44/100 */;
                    transform: translateY(-50%);
                }
            }
        }
        .el-menu-item-group__title {
            display: none;
        }
    }
}
</style>
