<template>
  <div id="app" class="app-wrap">
    <!-- 顶部 -->
    <Header :isFixed="isFixed"></Header>
    <div class="box">
      <div style="height: 100%">
        <router-view />
      </div>
    </div>
    <!-- 顶部 -->
    <Footer></Footer>
    <!-- 侧边悬浮 -->
    <Suspension></Suspension>
  </div>
</template>

<script>
import { Footer, Header, Suspension } from "@/components";

export default {
  components: {
    Footer,
    Header,
    Suspension
  },
  data() {
    return {
      isFixed: false
    }
  },
  watch: {
    $route(toRouter) {
      if (toRouter.name == 'home') {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    }
  }
}
</script>
<style lang="scss">
.app-wrap {
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .box {
    // flex: 1 0 auto;
    flex-grow: 1;
  }
}
</style>
