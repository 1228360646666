<template>
    <div class="header-wrap" :class="{ fixed: isFixed }">
        <div class="logo" @click="handleJump(column[0])">
            <img src="@/assets/images/logo.png" />
        </div>
        <div class="column-list">
            <div v-for="(item, index) in column" :key="index" class="item" @click="handleJump(item)">
                <div class="label">{{ item.label }}</div>
                <div v-if="item.nameList.includes($route.name)" class="selected" />
            </div>
        </div>
        <div class="phone-box">
            <div class="left">
                <img src="@/assets/images/phone.png" />
            </div>
            <div class="right">
                <div class="text">服务热线</div>
                <div class="text">400-000-1580</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isFixed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            column: [
                {
                    label: '首页',
                    nameList: ['home'],
                    link: '/'
                },
                {
                    label: '信息披露',
                    nameList: ['infoList', 'infoDetail'],
                    link: '/info/list'
                },
                {
                    label: '新闻动态',
                    nameList: ['newsList', 'newsDetail'],
                    link: '/news/list',

                }
            ],
        }
    },
    methods: {
        handleJump(item) {
            this.$router.push(item.link)
        }
    }
}
</script>

<style scoped lang="scss">
.header-wrap {
    width: 100%;
    height: 1rem;
    background: #FFFFFF;
    opacity: 1;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    padding: 0 .36rem /* 36/100 */;
    box-sizing: border-box;
    flex-shrink: 0;

    .logo {
        width: 1.69rem /* 169/100 */;
        margin-right: 66px;
        cursor: pointer;

        img {
            width: 100%;
            display: block;
        }
    }
    .column-list {
        flex: 1;
        display: flex;
        height: 100%;

        .item {
            margin-right: .58rem /* 58/100 */;
            min-width: 100px;
            text-align: center;
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                .label {
                    color: #AF282B;
                }
            }

            .label {
                font-size: .16rem /* 16/100 */;
                color: #333333;
                letter-spacing: 1px;
            }


            .selected {
                width: 100%;
                height: .08rem /* 8/100 */;
                background: #AF282B;
                position: absolute;
                bottom: 0px;
                left: 0px;
            }
        }
    }

    .phone-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        
        .left {
            margin-right: .11rem /* 11/100 */;

            img {
                width: .38rem /* 38/100 */;
                display: block;
            }
        }
        .right {
            .text {
                font-size: .16rem /* 16/100 */;
                color: #333333;
                line-height: .21rem /* 21/100 */;

                &:first-child {
                    margin-bottom: 3px;
                }
            }
        }

    }
}
.fixed {
    position: fixed;
    opacity: 0.8;
}
</style>
