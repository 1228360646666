<template>
    <div>
        <div class="content-box">
            <div class="prev item" v-if="beforeData" @click="handleJump(beforeData)">
                <div class="mark">上一篇</div>
                <div class="title ellspile">{{ beforeData.articleTitle }}</div>
            </div>
            <div class="next item" v-if="afterData" @click="handleJump(afterData)">
                <div class="mark">下一篇</div>
                <div class="title ellspile">{{ afterData.articleTitle }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        beforeData: {
            type: null | Object,
            default() {
                return null
            }
        },
        afterData: {
            type: null | Object,
            default() {
                return null
            }
        }
    },
    data() {
        return {
        }
    },
    methods: {
        handleJump(dataSource) {
            this.$router.push({
                path: `/news/detail/${dataSource.id}`,
                // query: {
                //     id: dataSource.id,
                // },
            });
        }
    }
}
</script>

<style scoped lang="scss">
.content-box {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .item {
        height: 1.57rem /* 157/100 */;
        width: 5.51rem /* 551/100 */;
        background: #FFFFFF;
        border-radius: .12rem /* 12/100 */;
        padding: .3rem /* 30/100 */ .37rem /* 37/100 */ .42rem /* 42/100 */;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
            background-color: #AF282B;

            .mark, .title {
                color: #FFFFFF;
            }
        }


        .mark {
            font-size: .16rem /* 16/100 */;
            color: #666666;
            line-height: .21rem /* 21/100 */;
        }

        .title {
            font-size: .2rem /* 20/100 */;
            color: #333333;
            margin-top: .38rem /* 38/100 */;
        }
    }

}
</style>