export default function () {
    const documentEl = document.documentElement;
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    const recalc = function () {
        var clientWidth = documentEl.clientWidth;
        clientWidth > 1920 && (clientWidth = 1920);
        clientWidth <= 1024 && (clientWidth = 1024);
        documentEl.style.fontSize = (clientWidth / 19.2) + 'px';
    };

    if (!document.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener('DOMContentLoaded', recalc, false);
}