<template>
    <div>
        <div class="content-box">
            <div class="left-box">
                <div class="date">
                    <div class="year">{{ dayjs(data.createTime).format('YYYY') }}</div>
                    <div class="month">{{ dayjs(data.createTime).format('MM-DD') }}</div>
                </div>
            </div>
            <div class="right-box">
                <div class="detail-box">
                    <div class="title">{{ data.title }}</div>
                    <div class="line"></div>
                    <div class="content" v-html="data.content"></div>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default() {
                return {
                    createTime: null,
                    title: '',
                    content: ''
                }
            }
        }
    },
}
</script>

<style scoped lang="scss">
    .content-box {
        display: flex;

        .left-box {
            margin-right: .23rem /* 23/100 */;
            flex-shrink: 0;

            .date {
                font-size: .16rem /* 16/100 */;
                color: #141414;
                line-height: .21rem /* 21/100 */;
                padding: 0 .2rem /* 20/100 */ 0 0;
                border-right: 1px solid #E8E8E8;

                .month {
                    font-size: .18rem /* 18/100 */;
                    margin-top: .08rem /* 8/100 */;
                }
            }
        }

        .right-box {
            flex: 1;

            .detail-box {
                .title {
                    font-size: .32rem /* 32/100 */;
                    color: #333333;
                    line-height: .42rem /* 42/100 */;
                }

                .line {
                    width: 100%;
                    height: 1px;
                    border-top: 1px solid #979797;
                    margin: .56rem /* 56/100 */ 0;
                }
                .content {
                    padding: 0 0 .55rem /* 55/100 */ 0;
                    font-size: initial;
                    word-break: break-all;

                    ::v-deep {
                        img {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }
</style>