import Vue from 'vue'
import ElementUI from 'element-ui';
import dayjs from "dayjs";
import App from './App.vue'
import router from './router'
import store from './store'
// 图片懒加载
import VueLazyload from "vue-lazyload";
import remResize from './utils/remResize'
// 引入flex快捷样式
import '@/assets/styles/commont.scss';
// 引入全局样式
import '@/assets/styles/index.scss'
// 引入element-ui样式
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/variables.scss'

Vue.use(ElementUI);
Vue.use(VueLazyload);
Vue.prototype.dayjs = dayjs

Vue.config.productionTip = false
remResize()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
