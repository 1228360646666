<template>
    <div class="suspension-wrap">
        <div class="item wx" @mouseenter="showQrCode = true" @mouseleave="showQrCode = false">
            <img src="@/assets/images/weixin.png" class="icon" />
            <div class="text">关注我们</div>
        </div>
        <div class="line" />
        <div class="item top" @click="goTopClick">
            <img src="@/assets/images/top.png" class="icon" />
            <div class="text">返回顶部</div>
        </div>
        <div class="qrcode" v-if="showQrCode">
            <img src="@/assets/images/gongzhonghao1.png" />
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                showQrCode: false
            }
        },
        methods: {
            goTopClick() {
                document.documentElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    }
</script>

<style scoped lang="scss">
.suspension-wrap {
    width: 60px;
    background: rgba(255,255,255,0.8100);
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.0900);
    border-radius: 8px;
    position: fixed;
    top: 188px;
    right: .2rem /* 20/100 */;
    z-index: 999;

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 18px 0 15px;
        cursor: pointer;

        .text {
            font-size: 12px;
            color: #333333;
            line-height: 16px;
            margin-top: 7px;
        }
    }
    .wx  {
        .icon {
            display: block;
            width: 31px;
            height: 26px;
        }
    }
    .top  {
        .icon {
            display: block;
            width: 28px;
            height: 28px;
        }
    }

    .line {
        width: 43px;
        height: 1px;
        background-color: #E3E3E3;
        margin: 0 auto;
    }

    .qrcode {
        width: 165px;
        height: 165px;
        background: rgba(255,255,255,0.66);
        border-radius: 16px;
        padding: 13px;
        position: absolute;
        top: 0;
        left: -174px;
        box-sizing: border-box;

        img {
            width: 100%;
            display: block;
        }
    }
}


</style>
