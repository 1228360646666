<template>
    <div class="commonTitle-wrap">
        <div class="top">
            <img src="@/assets/images/1657790194026.png" class="icon" />
            <div class="title">{{ title }}</div>
            <img src="@/assets/images/1657790194025.png" class="icon" />
        </div>
        <div class="content">{{ content }}</div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }
    }
}
</script>
<style scoped lang="scss">
.commonTitle-wrap {
    width: 100%;
    .icon {
        width: .58rem /* 58/100 */;
        height: .37rem /* 37/100 */;
    }
    .title {
        font-size: .48rem /* 48/100 */;
        font-weight: bold;
        color: #333333;
        line-height: .46rem /* 46/100 */;
        margin: 0 .14rem /* 14/100 */;
    }
    .content {
        font-size: .16rem /* 16/100 */;
        font-weight: 500;
        color: #999999;
        line-height: .19rem /* 19/100 */;
        text-align: center;
        margin-top: .2rem /* 20/100 */
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>