import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/news/list',
    name: 'newsList',
    component: () => import('@/views/News/List'),
  },
  {
    path: '/news/detail/:id',
    name: 'newsDetail',
    component: () => import('@/views/News/Detail'),
  },
  {
    path: '/info/list',
    name: 'infoList',
    component: () => import('@/views/Info/List'),
  },
  {
    path: '/info/detail/:id',
    name: 'infoDetail',
    component: () => import('@/views/Info/Detail'),
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: '/insurance',
  routes
})

export default router
