<template>
  <!-- 封装的翻页组件 -->
  <div class="my-pagination-container">
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      @current-change="handlePaginationChange">
  </el-pagination>
  </div>
</template>

<script>

export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    layout: {
      type: String,
      default: "prev, pager, next",
    },
    background: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  methods: {
    handlePaginationChange(val) {
      this.$emit("paginationChange", val);
    },
  },
};
</script>

<style scoped lang="scss">
.my-pagination-container {
  font-size: initial;

  ::v-deep {
    button, .el-pager li {
      background-color: #fff !important;
      color: #666666 !important;
      border: 1px solid #E6E6E6;
      font-size: 12px;
      font-weight: normal;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #AF282B !important;
      color: #fff !important;
    }
  }
}

/* .block >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #277f7b !important;
  color: white !important;
} */
</style>
